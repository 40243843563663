import * as React from "react";
import styled from "styled-components";
import HeroDesktop from "../images/hero_desktop.png";
import HeroMobile from "../images/hero_mobile.png";

const MainWrapper = styled.div`
  display: flex;
  width: 100%;
  max-width: 1216px;
  margin: 0 auto;
  padding-top: 160px;

  @media (max-width: 900px) {
    flex-direction: column-reverse;
    padding-top: 30px;
    overflow: hidden;
  }
`;

const HeroText = styled.div`
  display: flex;
  width: 100%;
  font-size: 64px;
  line-height: 96px;
  font-weight: bold;
  z-index: 1;
  padding: 0 32px;
  @media (max-width: 900px) {
    font-size: 40px;
    line-height: 60px;

    position: relative;
    top: -130px;
  }
  @media (max-width: 350px) {
    font-size: 32px;
    line-height: 60px;
  }
`;
const HeroImageWrapper = styled.div`
  display: flex;
  height: 500px;
  width: 100%;
  position: relative;
  @media (max-width: 900px) {
    display: none;
  }
`;

const HeroImageMobileWrapper = styled.div`
  display: none;
  height: 550px;
  width: 100%;
  position: relative;
  @media (max-width: 900px) {
    display: flex;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Hero = () => (
  <MainWrapper>
    <HeroText>
      web3
      <br />
      metaverse
      <br />
      AR/VR
      <br />
      software house
    </HeroText>
    <HeroImageWrapper>
      <ImageWrapper>
        <img src={HeroDesktop} alt="Hero" />
      </ImageWrapper>
    </HeroImageWrapper>
    <HeroImageMobileWrapper>
      <ImageWrapper>
        <img src={HeroMobile} alt="Hero" />
      </ImageWrapper>
    </HeroImageMobileWrapper>
  </MainWrapper>
);

export default Hero;
