import * as React from "react";
import Board from "../components/board";
import Hero from "../components/hero";
import Products from "../components/products";
import PartnersSlider from "../components/partnersSlider";
import { Flex } from "../components/Box";
import StyledButton from "../components/Button/StyledButton";
import styled from "styled-components";

const IndexPage = () => {
  return (
    <>
      <Hero />
      <Products />
      <Board />
      <Flex justifyContent="center">
        <StyledButton buttonDescription="Our Team" buttonHref="/our-team" />
      </Flex>
      <PartnersSlider />
    </>
  );
};

export default IndexPage;
