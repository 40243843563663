import * as React from "react";
import styled from "styled-components";
import LogoProtocol from "../svg/LogoProtocol";
import LogoWallet from "../svg/LogoWallet";
import LogoMarket from "../svg/LogoMarket";

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const BoxWrapper = styled.div`
  width: 100%;
  display: flex;

  align-items: center;
  max-width: 800px;
  min-height: 170px;
  padding: 32px 88px;
  position: relative;
  border-radius: 20px;
  background: #171c32;
  background-clip: padding-box;
  border: 4px solid transparent;
  ::after {
    content: "";
    top: -4px;
    bottom: -4px;
    left: -4px;
    right: -4px;
    z-index: -1;
    opacity: 0.4;
    position: absolute;
    border-radius: 20px;
    background: linear-gradient(180.46deg, #08f4e3 0.3%, #9b77f5 86.83%);
  }
  :hover {
    background: #1a1f38;
    background-clip: padding-box;
  }
  @media (max-width: 900px) {
    padding: 64px;
    justify-content: center;
  }
`;

const ContentWrapper = styled.div`
  gap: 84px;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  @media (max-width: 900px) {
    gap: 0px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const IconBox = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

const ProductIcon = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  padding-right: 61px;
  border-right: 1px solid rgba(155, 119, 245, 0.3);
  @media (max-width: 900px) {
    padding-right: 0px;
    border-right: none;
    border-bottom: 1px solid rgba(155, 119, 245, 0.3);
    padding-bottom: 32px;
    margin-bottom: 32px;
  }
`;

const ProductText = styled.div`
  display: flex;
  max-width: 310px;
  font-size: 18px;
  line-height: 130%;
  @media (max-width: 900px) {
    max-width: 200px;
    text-align: center;
  }
`;

const productsData = [
  {
    logo: <LogoMarket />,
    href: "https://www.metapromarket.com/",
    text: "Mint and trade NFT meta assets",
  },
  {
    logo: <LogoWallet />,
    href: "https://www.metaprowallet.com/",
    text: "Connect to metaverse",
  },
  {
    logo: <LogoProtocol />,
    href: "https://www.metaproprotocol.com/",
    text: "Building games and metaverses on one protocol with Unity support",
  },
];

const ProductBox = () => (
  <MainWrapper>
    {productsData.map((product) => {
      return (
        <BoxWrapper>
          <a
            href={product.href}
            target="_blank"
            style={{ textDecoration: "none", color: "#f3f3f3 " }}
          >
            <ContentWrapper>
              <ProductIcon>{product.logo}</ProductIcon>
              <ProductText>{product.text}</ProductText>
            </ContentWrapper>
          </a>
        </BoxWrapper>
      );
    })}
  </MainWrapper>
);

export default ProductBox;
