import * as React from "react";
import styled from "styled-components";
import ProductBox from "./productBox";

const MainWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  margin-top: 200px;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 0 40px;
  @media (max-width: 900px) {
    margin-top: 0px;
    margin-bottom: 80px;
  }
`;

const StyledText = styled.div`
  font-weight: bold;
  font-size: 48px;
  line-height: 115%;
  font-weight: bold;
  color: #f3f3f3;
  padding-bottom: 32px;
  @media (max-width: 900px) {
    text-align: center;
    font-size: 40px;
    padding-bottom: 32px;
  }
`;

const Products = () => (
  <MainWrapper>
    <StyledText>Our products</StyledText>
    <ProductBox />
  </MainWrapper>
);

export default Products;
