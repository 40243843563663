import * as React from "react";
import styled from "styled-components";
import Michal from "../images/team/Michal.png";
import Marcin from "../images/team/Marcin.png";
import Sameer from "../images/team/Sameer.png";
import border from "../images/border.png";

const MainWrapper = styled.div`
  display: flex;
  max-width: 1216px;
  margin: 0 auto;
  flex-direction: column;
  margin-top: 64px;
  padding: 64px 0 0 0;
  @media (max-width: 900px) {
    padding: 0 24px;
    margin-top: 0px;
  }
`;

const ComponentTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 48px;
  line-height: 87px;
  font-weight: bold;
  padding-bottom: 32px;
  @media (max-width: 900px) {
    font-size: 40px;
    padding-bottom: 32px;
  }
`;

const Name = styled.div`
  display: flex;
  font-size: 20px;
  padding: 8px 0px;
  font-weight: bold;
  line-height: 30px;
  text-align: center;
  justify-content: center;
  @media (max-width: 900px) {
    justify-content: center;
  }
`;
const Position = styled.div`
  display: flex;
  jsutify-content: center;
  text-align: center;
  font-size: 18px;
  line-height: 23px;
  color: #f5f5f5;
`;

const ColumnWrapper = styled.div`
  display: flex;
  align-items: center;
  height: max-content;
  width: 240px;
  flex-direction: column;
  padding-bottom: 32px;
`;

const RowWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 756px) {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
`;

const BorderWrapper = styled.div`
  display: flex;
  position: relative;
  padding-bottom: 16px;
  @media (max-width: 900px) {
    justify-content: center;
  }
`;

const ImageWrapper = styled.div`
  display: flex;
  position: absolute;
  z-index: -1;
`;

const teamData = [
  {
    name: "Michal Bartczak",
    position: "Co-founder and CEO/CTO",
    profilePicture: Michal,
  },
  {
    name: "Marcin Wojcieszkiewicz",
    position: "Co-founder and COO ",
    profilePicture: Marcin,
  },
  {
    name: "Sameer Khurana",
    position: "Co-founder & Chief Business Development Officer EYWA",
    profilePicture: Sameer,
  },
];

const Board = () => (
  <MainWrapper>
    <ComponentTitle>Board</ComponentTitle>
    <RowWrapper>
      {teamData.map((person, index) => {
        return (
          <ColumnWrapper key={index}>
            <BorderWrapper>
              <img src={border} alt="border" />
              <ImageWrapper>
                <img src={person.profilePicture} alt="profilePicture" />
              </ImageWrapper>
            </BorderWrapper>
            <Name>{person.name}</Name>
            <Position>{person.position}</Position>
          </ColumnWrapper>
        );
      })}
    </RowWrapper>
  </MainWrapper>
);

export default Board;
