import { ButtonBase } from "@mui/material";
import styled from "styled-components";
import * as React from "react";

const Button = styled.button`
  font-size: 18px;
  border: none;
  font-weight: bold;
  min-width: max-content;
  cursor: pointer;
  display: flex;
  align-items: center;
  padding: 16px 32px;
  color: #ffffff;
  background: linear-gradient(
    247.57deg,
    rgba(196, 196, 196, 0.2) 0%,
    #7763f6 0.01%,
    #17c6de 0.02%,
    #7763f6 99.98%,
    #5387ed 99.99%,
    #0ad2da 100%
  );
  border-radius: 8px;
  &:hover {
    background: linear-gradient(
      147.57deg,
      rgba(196, 196, 196, 0.2) 0%,
      #7763f6 0.01%,
      #17c6de 0.02%,
      #7763f6 99.98%,
      #5387ed 99.99%,
      #0ad2da 100%
    );
  }
  @media (max-width: 420px) {
    font-size: 16px;
  }
`;

const ButtonBox = styled.a`
  display: flex;
  width: max-content;
  height: max-content;
  text-decoration: none;
  color: #fff;
`;
interface IStyledButtonProps {
  buttonDescription: string;
  buttonHref?: string;
  onClick?: () => void;
}

const StyledButton: React.FC<IStyledButtonProps> = ({
  buttonDescription,
  buttonHref,
  onClick,
}) => {
  return (
    <ButtonBox href={buttonHref}>
      <Button onClick={onClick}>{buttonDescription}</Button>
    </ButtonBox>
  );
};

export default StyledButton;
